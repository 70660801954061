import React, {Component} from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {compose} from "redux";
import {connect} from "react-redux";
import Firebase from "firebase";
import {firestore} from "../../firebase";
import moment from "moment";

export const type = 'guestCounts';

class ViewComponent extends Component {

  state = {
    rows: [],
  };

  componentDidMount() {
    const {startDate, endDate} = this.props;

    const start = Firebase.firestore.Timestamp.fromDate(startDate);
    const end = Firebase.firestore.Timestamp.fromDate(endDate);

    firestore.collectionGroup('guest-history')
      .where('time', '>=',start)
      .where('time','<=', end)
      .where('type', '==', 'GUEST_GETTING_IN')
      .get().then(snapshot => {
        const startMoment = moment(startDate);
        const endMoment = moment(endDate);

        const rows = [];

        while (startMoment.isBefore(endMoment)) {
          const _endMoment = startMoment.clone().endOf('month');

          const docsInTimerange = snapshot.docs.filter(doc =>
            moment(doc.data().time.toDate()).isBetween(startMoment, _endMoment));

          const timeRange = startMoment.format('MMMM');

          rows.push({timeRange, guestCount: docsInTimerange.length});

          startMoment.add(1, 'month');
        }

        this.setState({ rows })
      });
  }

  render() {

    const {rows} = this.state;

    return (
      <Table>
        <TableHead>

          <TableRow>
            <TableCell>Zeitraum</TableCell>
            <TableCell numeric>Anzahl Gäste</TableCell>
          </TableRow>

        </TableHead>

        <TableBody>

          {
            rows.map(row => (
              <TableRow>
                <TableCell>{row.timeRange}</TableCell>
                <TableCell numeric>{row.guestCount}</TableCell>
              </TableRow>
            ))
          }

        </TableBody>
      </Table>
    );
  }
}

const mapStateToProps = state => ({
  startDate: state.statistic.statistic.timeRange.start,
  endDate: state.statistic.statistic.timeRange.end,
});
const mapDispatchToProps = dispatch => ({
});

export const View = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ViewComponent);
