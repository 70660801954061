import * as R from "ramda";
import {GET_LIST} from 'react-admin';
import {isBaseDataAvailable} from "./StatisticSelectors";

const initialStatisticState = {
  statistic: {},
  loadedBaseData: {
  },
  data: {}
};

export const statisticMiddleware = store => next => action => {
  next(action);
  const state = store.getState();

  if (action.type === 'BUS/SHOW_STATS' && !isBaseDataAvailable(state)) {
    const action = resource => ({type: 'BUS/FETCH_BASE_DATA', payload: {resource}, meta: {fetch: GET_LIST, resource}});
    store.dispatch(action('drivers'));
    store.dispatch(action('tickets'));
    store.dispatch(action('halts'));
  }


  if (action.type === 'BUS/FETCH_BASE_DATA_SUCCESS'
    || (action.type === 'BUS/SHOW_STATS' && isBaseDataAvailable(state))) {
    // do fetch nicely
  }
};


export function statisticReducer(state = initialStatisticState, action) {
  const {type, payload} = action;
  switch (type) {
    case 'BUS/SHOW_STATS': {
      return R.assoc('statistic', payload, state);
    }

    case 'BUS/RESET_STATS': {
      return R.assoc('statistic', {}, state);
    }

    case 'BUS/FETCH_BASE_DATA': {
      return R.assocPath(['loadedBaseData', payload.resource], false, state);
    }

    case 'BUS/FETCH_BASE_DATA_SUCCESS': {
      const { requestPayload: { resource }} = action;
      return R.assocPath(['loadedBaseData', resource], true, state);
    }

    default:
      return state;
  }
}
