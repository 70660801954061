import React from 'react';
import {Admin} from 'react-admin';
import {Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import {createHashHistory} from 'history';
import Resources from './resources'
import firestoreProvider from './firebase/FirestoreProvider';
import authProvider from './firebase/FireAuthClient'
import AppLayout from './layout/AppLayout';
import i18nProvider from './i18n/provider.js';
import LoginPage from './layout/LoginPage';
import createAdminStore from './store/createAdminStore';
import StatisticView from "./statistics/StatisticView";

const history = createHashHistory();

const customRoutes = [
  <Route exact path="/statistic" component={StatisticView}/>
];

const App = ({title, customer}) => (
  <Provider
    store={createAdminStore({
      authProvider,
      dataProvider: firestoreProvider(customer),
      i18nProvider,
      history,
    })}
  >
    <Admin
      title={title}
      loginPage={LoginPage}
      authProvider={authProvider}
      appLayout={AppLayout}
      customRoutes={customRoutes}
      locale="de"
      i18nProvider={i18nProvider}
      history={history}
    >
      {
        Resources
      }
    </Admin>
  </Provider>
);

export default App;
