import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import moment from "moment";
import 'moment/locale/de';

moment.locale('de');

ReactDOM.render(
  <App
    title="Bürgerbus Uedem"
    customer="uedem"
  />,
  document.getElementById('root')
);
