import germanMessages from 'ra-language-german';

export default {
  ra: {
    ...germanMessages.ra,
    navigation: {
      ...germanMessages.ra.navigation,
      page_rows_per_page: 'Zeilen pro Seite:',
    },
  },

  layout: {
    loginPage: {
      welcome: "Willkommen",
      email: "E-Mail",
      rememberMe: "Angemeldet bleiben",
    },
  },

  features: {
    statistics: {
      name: 'Statistiken',
      reports: {
        moneyDistributionAndDriverUtilisation: 'Fahrer- und Ticketübersicht',
        guestCounts: 'Fahrgastzahlen',
        guestsPerHalt: 'Fahrgäste je Haltestelle'
      }
    },
  },

  resources: {
    drivers: {
      name: 'Fahrer',

      fields: {
        firstname: 'Vorname',
        lastname: 'Nachname',
        email: 'E-Mail',
        mobile: 'Handynummer',
      },
    },

    halts: {
      name: 'Haltestellen',

      fields: {
        name: "Haltestelle",
        latitude: "Breitengrad (LAT)",
        longitude: "Höhengrad (LNG)",
        arrival_count: "Ankunftszähler"
      },

      helper: {
        arrival_count: "Z.B.: Wenn der Bus an der Haltestelle wendet, dann fährt er diese 2 mal an!"
      }
    },

    routes: {
      name: 'Routen',

      fields: {
        stops: 'In der Route anzufahrende Haltestellen',
        halt: 'Haltestelle',
        times: 'Abfahrzeiten',
      },
      hints: {
        defaultTime: "07:35",
        helperTextTime: "Beispiel: 07:35",
      },
    },

    tickets: {
      name: 'Tickets',

      fields: {
        name: 'Ticketbezeichnung',
        price: 'Preis',
        printTicket: 'Ticket drucken?',
      },
    }
  }
};
