import React from 'react';
import {
  Resource,
  Datagrid,
  TextField,
  NumberField,
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  EditButton
} from 'react-admin';
import ResourceTitle from '../components/ResourceTitle';
import ResourcePagination from '../components/ResourcePagination';
import ResourceIcon from '@material-ui/icons/Receipt';
import ResourceDefaultActions from '../components/ResourceDefaultActions';

const ResourceList = (props) => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }} actions={<ResourceDefaultActions />} perPage={75} pagination={<ResourcePagination />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <NumberField source="price" options={{ style: 'currency', currency: 'EUR' }} />
      <EditButton />
    </Datagrid>
  </List>
);

const TicketInput = <SimpleForm>
  <TextInput source="name" />
  <NumberInput
    source="price"
    inputProps={{
      step: 0.01,
      min: 0,
    }}
  />
  <BooleanInput source="printTicket" />
</SimpleForm>;

const ResourceEdit = (props) => (
  <Edit title={<ResourceTitle prefix="Ticket" />} {...props}>
    {TicketInput}
  </Edit>
);

const ResourceCreate = (props) => (
  <Create {...props}>
    {TicketInput}
  </Create>
);

export default <Resource
  name="tickets"
  icon={ResourceIcon}
  list={ResourceList}
  edit={ResourceEdit}
  create={ResourceCreate}
/>
