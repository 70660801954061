import React from 'react';
import { CardActions, CreateButton } from 'react-admin';

const ResourceDefaultActions = ({
  bulkActions,
  basePath,
  // currentSort,
  displayedFilters,
  // exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  // total, 
  customActions
}) => (
    <CardActions>
      {bulkActions && React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
      {customActions}
    </CardActions>
  );

export default ResourceDefaultActions;