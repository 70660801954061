import React, {Component} from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {compose} from "redux";
import {connect} from "react-redux";
import Firebase from "firebase";
import {firestore} from "../../firebase";
import {isBaseDataAvailable} from "../redux/StatisticSelectors";

export const type = 'guestsPerHalt';

class ViewComponent extends Component {

  state = {
    rows: [],
    fetchedStatisticData: false,
  };

  render() {
    const {rows, fetchedStatisticData} = this.state;
    const {startDate, endDate, loadedBaseData, halts} = this.props;

    if (!fetchedStatisticData && loadedBaseData) {
      const start = Firebase.firestore.Timestamp.fromDate(startDate);
      const end = Firebase.firestore.Timestamp.fromDate(endDate);

      firestore.collectionGroup('guest-history')
        .where('time', '>=',start)
        .where('time','<=', end)
        .get().then(snapshot => {
          const reducedData = snapshot.docs.reduce((acc, doc) => {
            const data = doc.data();
            const haltId = data.halt.id;
            if (Object.keys(acc).includes(haltId)) {
              if (data.type === 'GUEST_GETTING_IN') {
                acc[haltId].entranceCount += 1;
              } else {
                acc[haltId].exitCount += 1;
              }
            } else {
              acc[haltId] = {
                halt: halts[haltId].name,
                [data.type === 'GUEST_GETTING_IN' ? 'entranceCount' : 'exitCount' ]: 1,
                [data.type !== 'GUEST_GETTING_IN' ? 'entranceCount' : 'exitCount' ]: 0,
              }
            }

            return acc;
          }, {});

          this.setState({
            rows: Object.entries(reducedData).map(set => set[1]),
            fetchedStatisticData: true,
          })
      });
    }

    return (
      <Table>
        <TableHead>

          <TableRow>
            <TableCell>Haltestelle</TableCell>
            <TableCell numeric>Anzahl Einstiege</TableCell>
            <TableCell numeric>Anzahl Ausstiege</TableCell>
          </TableRow>

        </TableHead>

        <TableBody>

          {
            rows.map(row => (
              <TableRow>
                <TableCell>{row.halt}</TableCell>
                <TableCell numeric>{row.entranceCount}</TableCell>
                <TableCell numeric>{row.exitCount}</TableCell>
              </TableRow>
            ))
          }

        </TableBody>
      </Table>
    );
  }
}

const mapStateToProps = state => ({
  loadedBaseData: isBaseDataAvailable(state),
  startDate: state.statistic.statistic.timeRange.start,
  endDate: state.statistic.statistic.timeRange.end,
  halts: state.admin.resources.halts.data,
});

const mapDispatchToProps = dispatch => ({});

export const View = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ViewComponent);
