import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import StatisticSelectionDialog from "./StatisticSelectionDialog";
import {compose} from "redux";
import {connect} from "react-redux";
import {isStatisticNotSelected, selectSelectedStatisticType, selectTimeRange} from "./redux/StatisticSelectors";
import Card from "@material-ui/core/Card";
import {Authenticated, Title, translate} from 'react-admin';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import * as MoneyDistribution from './reports/MoneyDistributionAndDriverUtilisation';
import * as GuestCounts from './reports/GuestCounts';
import * as GuestsPerHalt from './reports/GuestsPerHalt';
import {withRouter} from "react-router-dom";

const styles = theme => ({
  menuButton: {
    marginLeft: 'auto',
  },
  timeRange: {
    marginLeft: 2 * theme.spacing.unit,
  }
});

const StatisticReportContainer = ({statisticType}) => ({
  [MoneyDistribution.type]: <MoneyDistribution.View/>,
  [GuestCounts.type]: <GuestCounts.View/>,
  [GuestsPerHalt.type]: <GuestsPerHalt.View/>,
}[statisticType]);


const StatisticView = ({
                         classes, isStatisticSelected, statisticType, resetStatistic, translate, startDate, endDate, location
                       }) => {
  const statisticName = translate(`features.statistics.reports.${statisticType}`);
  return <Authenticated location={location}>
    <div>
      <Title title="Statistiken"/>
      {
        isStatisticSelected
          ? <StatisticSelectionDialog/>
          : (
            <Card>
              <Toolbar>
                <Typography variant="headline">{statisticName}</Typography>
                <Typography className={classes.timeRange} variant="body1">im
                  Zeitraum {startDate} bis {endDate}</Typography>

                <Button color="inherit" className={classes.menuButton} size={"small"} onClick={resetStatistic}>Statistik
                  wechseln</Button>
              </Toolbar>
              <CardContent>
                <StatisticReportContainer statisticType={statisticType}/>
              </CardContent>
            </Card>
          )
      }
    </div>
  </Authenticated>
};

const mapStateToProps = state => ({
  isStatisticSelected: isStatisticNotSelected(state),
  statisticType: selectSelectedStatisticType(state),
  startDate: selectTimeRange(state, 'start'),
  endDate: selectTimeRange(state, 'end'),
});

const mapDispatchToProps = dispatch => ({
  resetStatistic: () => dispatch({type: 'BUS/RESET_STATS'})
});

export default compose(
  translate,
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(StatisticView)
