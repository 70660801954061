import React from 'react';
import {Button, Dialog, DialogActions, DialogTitle, Typography,} from '@material-ui/core';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import StepContent from "@material-ui/core/StepContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import * as R from "ramda";
import {compose} from "redux";
import {connect} from "react-redux";
import moment from "moment";
import * as MoneyDistribution from "./reports/MoneyDistributionAndDriverUtilisation";
import * as GuestCounts from "./reports/GuestCounts";
import * as GuestsPerHalt from "./reports/GuestsPerHalt";
import { translate} from 'react-admin';

const styles = theme => ({
  timeSelectionLabel: {
    marginRight: 2 * theme.spacing.unit,
    width: "15%",
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: "30%"
  },
  upperTimeSelectionGrid: {
    marginBottom: 3 * theme.spacing.unit
  },
});

class StatisticSelectionDialog extends React.Component {

  state = {
    activeStep: 0,
    selectedTimeRange: {
      start: moment().startOf('month').toDate(),
      end: moment().endOf('month').toDate(),
    },
    selectedStatistic: ''
  };

  handleNext = () => {
    if (this.state.activeStep === 1 && this.state.selectedStatistic) {
      this.props.showReport(this.state.selectedStatistic, this.state.selectedTimeRange);
    } else {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  handleTimeRangeMonthSelection = (type, value) => {
    const fullYear = this.state.selectedTimeRange[type].getFullYear();
    let date = moment().year(fullYear).month(value);

    date = type === 'start' ? date.startOf('month') : date.endOf('month');

    this.setState(R.assocPath(['selectedTimeRange', type], date.toDate(), this.state));
  };

  handleTimeRangeYearSelection = (type, value) => {
    const month = this.state.selectedTimeRange[type].getMonth();
    let date = moment().year(value).month(month);

    date = type === 'start' ? date.startOf('month') : date.endOf('month');

    this.setState(R.assocPath(['selectedTimeRange', type], date.toDate(), this.state));
  };

  handleStatisticSelection = value => {
    this.setState(R.assoc('selectedStatistic', value, this.state))
  };

  render() {
    const steps = ['Zeitraum der Statistik wählen', 'Zu erstellende Statistik wählen'];

    const { classes, translate } = this.props;
    const { activeStep, selectedTimeRange: { start, end }, selectedStatistic } = this.state;

    const startYear = start.getFullYear();
    const startMonth = start.getMonth();

    const endYear = end.getFullYear();
    const endMonth = end.getMonth();

    return (
      <Dialog
        open
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Statistische Auswertung erstellen</DialogTitle>

        <Stepper
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map((label) =>
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent className={classes.content}>

                {
                  {
                    0: (<React.Fragment>

                      <Grid
                        className={classes.upperTimeSelectionGrid}
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        container
                      >
                        <Typography
                          className={classes.timeSelectionLabel}
                          variant="subheading"
                        >
                          Beginn:
                        </Typography>

                        <TextField
                          className={classes.textField}
                          type="number"
                          label="Jahr"
                          value={startYear}
                          onChange={e => this.handleTimeRangeYearSelection('start', e.target.value)}
                        />
                        <TextField
                          className={classes.textField}
                          type="number"
                          label="Monat"
                          value={startMonth + 1}
                          onChange={e => this.handleTimeRangeMonthSelection('start', e.target.value - 1)}
                        />
                      </Grid>

                      <Grid
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        container
                      >
                        <Typography
                          className={classes.timeSelectionLabel}
                          variant="subheading"
                        >
                          Ende:
                        </Typography>

                        <TextField
                          className={classes.textField}
                          type="number"
                          label="Jahr"
                          value={endYear}
                          onChange={e => this.handleTimeRangeYearSelection('end', e.target.value)}
                        />
                        <TextField
                          className={classes.textField}
                          type="number"
                          label="Monat"
                          value={endMonth + 1}
                          onChange={e => this.handleTimeRangeMonthSelection('end', e.target.value - 1)}
                        />
                      </Grid>

                    </React.Fragment>),

                    1: (<React.Fragment>

                      <RadioGroup
                        aria-label="Gender"
                        name="gender1"
                        className={classes.group}
                        onChange={e => this.handleStatisticSelection(e.target.value)}
                        value={selectedStatistic}
                      >

                        <FormControlLabel
                          value={GuestCounts.type}
                          control={<Radio/>}
                          label={translate(`features.statistics.reports.${GuestCounts.type}`)}
                        />
                        <FormControlLabel
                          value={MoneyDistribution.type}
                          control={<Radio/>}
                          label={translate(`features.statistics.reports.${MoneyDistribution.type}`)}
                        />
                        <FormControlLabel
                          value={GuestsPerHalt.type}
                          control={<Radio/>}
                          label={translate(`features.statistics.reports.${GuestsPerHalt.type}`)}
                        />

                      </RadioGroup>

                    </React.Fragment>),

                  }[activeStep]
                }

              </StepContent>
            </Step>
          )}
        </Stepper>

        <DialogActions>
          <Button disabled={activeStep === 0} color="primary" onClick={this.handleBack}>
            Zurück
          </Button>
          <Button color="primary" onClick={this.handleNext}>
            {activeStep !== steps.length - 1
              ? "Weiter"
              : "Statistik anzeigen"
            }
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showReport: (report, timeRange) => dispatch({
      type: 'BUS/SHOW_STATS',
      payload: {report, timeRange},
    })
  }
}

export default compose(
  withStyles(styles),
  translate,
  connect(null, mapDispatchToProps)
)(StatisticSelectionDialog);
