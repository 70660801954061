import { firestore } from "../firebase";
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  GET_MANY_REFERENCE
} from "react-admin";

let root, responseCache;

export default rootCollectionPath => {
  root = firestore.collection(rootCollectionPath);
  return firestoreProvider;
};

const firestoreProvider = (type, resource, params) => {
  return new Promise((resolve, reject) => {
    switch (type) {
      case GET_MANY:
      case GET_MANY_REFERENCE:
      case GET_LIST:
        let ref = root.doc(resource).collection("list");

        if (params === undefined)
          params = {};

        if (params.sort && params.sort.field !== "id") {
          const { field, order } = params.sort;
          if (order === "ASC")
            ref = ref.orderBy(field);
          else
            ref = ref.orderBy(field, "desc");
        }

        ref.get().then(snapshot => {
          responseCache = [];
          snapshot.forEach(doc => {
            responseCache.push({ ...doc.data(), id: doc.id });
          });

          const total = responseCache.length;
          // TODO implement firebase pagination algorithm with tracked data store
          if (params.pagination) {
            const { page, perPage } = params.pagination
            const start = (page - 1) * perPage
            const end = page * perPage
            responseCache = responseCache.slice(start, end)
          }

          if (params.filter) {
            if (params.filter.q) {
              const query = (params.filter.q + "").toLowerCase();
              responseCache = responseCache.filter(obj => Object.values(obj).filter(prop => ("" + prop).toLowerCase().includes(query)).length > 0);
            }
          }

          resolve({
            data: responseCache,
            total: total
          });
        }).catch(e => { reject(e) });
        break;
      case GET_ONE:
        root.doc(resource).collection("list").doc(params.id).get().then(doc => {
          resolve({ data: { ...doc.data(), id: doc.id } })
        }).catch(e => { reject(e) });
        break;
      case CREATE:
        root.doc(resource).collection("list").add(params.data).then(ref => {
          resolve({ data: { ...params.data, id: ref.id } });
        }).catch(e => { reject(e) });
        break;
      case UPDATE:
        delete params.data["id"];
        root.doc(resource).collection("list").doc(params.id).set(params.data).then(() => {
          resolve({ data: { ...params.data, id: params.id } });
        }).catch(e => { reject(e) });
        break;
      case UPDATE_MANY:
      case DELETE:
        root.doc(resource).collection("list").doc(params.id).delete().then(() => {
          resolve({ data: params.previousData });
        });
        break;
      case DELETE_MANY:
        responseCache = [];
        params.ids.forEach(id => {
          root.doc(resource).collection("list").doc(id).delete().then(() => {
            responseCache.push(id);
          });
        });
        resolve({ data: responseCache });
        break;
      default:
        break;
    }
  });
};