import React from 'react';
import {
  Resource,
  Datagrid,
  TextField,
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,

} from 'react-admin';
import ResourceIcon from '@material-ui/icons/People';
import ResourceDefaultActions from '../components/ResourceDefaultActions';
import ResourcePagination from '../components/ResourcePagination';

const ResourceList = (props) => (
  <List {...props} sort={{ field: 'lastname', order: 'ASC' }} actions={<ResourceDefaultActions />} perPage={75} pagination={<ResourcePagination />}>
    <Datagrid rowClick="show">
      <TextField source="lastname" />
      <TextField source="firstname" />
      <EditButton />
    </Datagrid>
  </List>
);

const DriverInput = (
  <SimpleForm>
    <TextInput source="lastname" />
    <TextInput source="firstname" />
  </SimpleForm>
);

const ResourceEdit = (props) => (
  <Edit title={<Title />} {...props}>
    {DriverInput}
  </Edit>
);

const ResourceCreate = (props) => (
  <Create {...props}>
    {DriverInput}
  </Create>
);

export default <Resource
  name="drivers"
  icon={ResourceIcon}
  list={ResourceList}
  edit={ResourceEdit}
  create={ResourceCreate}
/>

const Title = ({ record }) => (
  <span>
    {record.lastname + ", " + record.firstname}
  </span>
);