import React from 'react';
import MaterialUiChipInput from './MaterialChipInput';
import { translate } from 'react-admin';
import { Field } from "redux-form";

class ChipInput extends React.Component {

  state = {
    helperTextNeeded: false,
  }

  handleChange = (chips) => {
    this.props.input.onChange(chips);
    this.forceUpdate();
  };

  validateNewChip = (newChip) => {
    const valid = newChip.match('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
    
    this.setState({
      helperTextNeeded: !valid,
    });

    return valid;
  }

  render() {
    const { input, translate, label } = this.props;    
    const { helperTextNeeded } = this.state;

    const inputNotEmpty = input && input.value;
    
    const value = inputNotEmpty ? input.value : [];
    const placeholder = inputNotEmpty ? "" : translate("resources.routes.hints.defaultTime");
    const helperText = helperTextNeeded ? translate("resources.routes.hints.helperTextTime") : "";
    const helperTextStyle = { marginBottom: helperTextNeeded ? 24 : undefined };

    return <MaterialUiChipInput {...this.props}
      label={translate(label)}
      defaultValue={value}
      helperText={helperText}
      placeholder={placeholder}
      onBeforeAdd={this.validateNewChip}
      onChange={this.handleChange}
      style={helperTextStyle}
      fullWidth={true}
      margin="normal"
    />
  }
};

const validator = (value) => {
  let validTimes = false;
  
  if (Array.isArray(value)) {    
    validTimes = true;    
    value.forEach(val => {
      if (!val.match('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')) {
        validTimes = false;
      }
    });    
  }

  return validTimes ? undefined : "Error";
}

export default (props) => <Field
  name={props.source}
  component={translate(ChipInput)}
  validate={validator}
  {...props}
/>;
