import React, {Component} from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {compose} from "redux";
import {connect} from "react-redux";
import {firestore} from "../../firebase";
import Firebase from 'firebase';
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {isBaseDataAvailable} from "../redux/StatisticSelectors";

export const type = 'moneyDistributionAndDriverUtilisation';

const styles = theme => ({
  spacing: {
    marginBottom: 4 * theme.spacing.unit,
  },
});

class ViewComponent extends Component {

  state = {
    driverStats: [],
    ticketDistribution: [],
    fetchedStatisticData: false,
  };

  async fetchStatisticData() {
    const {startDate, endDate, tickets, drivers} = this.props;

    const start = Firebase.firestore.Timestamp.fromDate(startDate);
    const end = Firebase.firestore.Timestamp.fromDate(endDate);

    const ticketHistorySnapshot = await firestore.collectionGroup('ticket-history')
      .where('time', '>=', start)
      .where('time', '<=', end)
      .get();

    /*
    const drivingRecordSnapshot = await firestore.collection('/uedem/statistics/driving-record')
      .where('start', '>=', start)
      .where('start', '<=', end)
      .get();
    */

    // const driverStats = this.aggregateDriverStats(drivingRecordSnapshot, drivers);
    const ticketDistribution = this.aggregateTicketDistribution(ticketHistorySnapshot, tickets);

    this.setState({
      ...this.state,
      fetchedStatisticData: true,
      // driverStats,
      ticketDistribution,
    });
  }


  aggregateDriverStats(drivingRecordSnapshot, drivers) {
    const reducedDrivingData = drivingRecordSnapshot.docs.reduce((acc, doc) => {
      // <TableCell numeric>Anzahl der Einsätze</TableCell>
      // <TableCell numeric>Gefahrene Zeit</TableCell>
      // <TableCell numeric>Einnahmen</TableCell>

    }, {});

    return Object.entries(reducedDrivingData).map(set => set[1]);
  }

  aggregateTicketDistribution(ticketHistorySnapshot, tickets) {
    let totalSells = 0;
    const reducedTicketData = ticketHistorySnapshot.docs.reduce((acc, doc) => {
      const data = doc.data();
      const ticketId = data.ticket.id;
      const ticket = tickets[ticketId];

      if (Object.keys(acc).includes(ticketId)) {
        acc[ticketId].revenue += ticket.price;
        acc[ticketId].sells += 1;
      } else {
        acc[ticketId] = {
          name: ticket.name,
          revenue: ticket.price,
          sells: 1,
        }
      }

      totalSells += 1;

      return acc;
    }, {});

    Object.keys(reducedTicketData).forEach(key =>
      reducedTicketData[key].share = reducedTicketData[key].sells / totalSells);

    return Object.entries(reducedTicketData).map(set => set[1]);
  }

  render() {
    const {driverStats, ticketDistribution, fetchedStatisticData} = this.state;
    const {classes, loadedBaseData} = this.props;

    if (!fetchedStatisticData && loadedBaseData) {
      this.fetchStatisticData();
    }

    return (
      <React.Fragment>

        <Typography variant="subheading">Ticketübersicht</Typography>

        <Table>
          <TableHead>

            <TableRow>
              <TableCell>Ticket</TableCell>
              <TableCell numeric>Anzahl der Verkäufe</TableCell>
              <TableCell numeric>Verkaufserlöse</TableCell>
              <TableCell numeric>Verkaufsanteil</TableCell>
            </TableRow>

          </TableHead>

          <TableBody>

            {
              ticketDistribution.map(row => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell numeric>{formatNumber(row.sells, 0)}</TableCell>
                  <TableCell numeric>{formatNumber(row.revenue)} €</TableCell>
                  <TableCell numeric>{formatNumber(row.share * 100)} %</TableCell>
                </TableRow>
              ))
            }

          </TableBody>
        </Table>

        <div className={classes.spacing}/>

        <Typography variant="subheading">Fahrerübersicht</Typography>

        <Table>
          <TableHead>

            <TableRow>
              <TableCell>Bürgerbus-Fahrer</TableCell>
              <TableCell numeric>Anzahl der Einsätze</TableCell>
              <TableCell numeric>Gefahrene Zeit</TableCell>
              <TableCell numeric>Einnahmen</TableCell>
            </TableRow>

          </TableHead>

          <TableBody>

            {
              driverStats.map(row => (
                <TableRow key={row.driver}>
                  <TableCell>{row.driver}</TableCell>
                  <TableCell numeric>{row.missionNumber}</TableCell>
                  <TableCell numeric>{row.drivenTime}</TableCell>
                  <TableCell numeric>{row.income}</TableCell>
                </TableRow>
              ))
            }

          </TableBody>
        </Table>

      </React.Fragment>
    );
  }
}

function formatNumber(number, digits = 2) {
  return number.toLocaleString('de-DE', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  });
}

const mapStateToProps = state => ({
  loadedBaseData: isBaseDataAvailable(state),
  startDate: state.statistic.statistic.timeRange.start,
  endDate: state.statistic.statistic.timeRange.end,
  drivers: state.admin.resources.drivers.data,
  tickets: state.admin.resources.tickets.data,
});

const mapDispatchToProps = dispatch => ({});

export const View = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ViewComponent);
