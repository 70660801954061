import {AUTH_CHECK, AUTH_LOGIN, AUTH_LOGOUT} from 'react-admin'
import firebase from '../firebase';

export default async (type, params) => {
  if (type === AUTH_LOGOUT) {
    return firebase.auth().signOut();
  }

  if (type === AUTH_CHECK) {

    if (firebase.auth().currentUser) {
      await firebase.auth().currentUser.reload();
    } else {
      throw new Error('sign_in_error');
    }

    return true;
  }

  if (type === AUTH_LOGIN) {
    const {username, password} = params;

    let authResponse = false;

    try {
      authResponse = await firebase.auth().signInWithEmailAndPassword(username, password);
    } catch (e) {
      throw new Error('sign_in_error')
    }

    return authResponse;
  }

  return false;
};
