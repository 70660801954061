import {SimpleForm} from "react-admin";
import React from "react";

export function SimpleFormWithDataTransform(props) {
  const {save, transform} = props

  const overrideSave = (data, redirect) => save(transform(data), redirect)

  return <SimpleForm {...props} save={overrideSave}>
    {props.children}
  </SimpleForm>
}
