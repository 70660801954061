import React from 'react';

const ResourceTitle = ({ record, source, prefix, suffix }) => (
  <span>
    {prefix ? prefix + ' ' : ''}
    {record && source ? `${record[source]}` : ''}
    {suffix || ''}
  </span>
);

export default ResourceTitle;