import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {withRouter} from 'react-router-dom';
import {getResources, MenuItemLink, translate} from 'react-admin';
import StatisticIcon from '@material-ui/icons/ShowChart';
import {Divider} from "@material-ui/core";

class AppMenu extends Component {
  static propTypes = {
    onMenuClick: PropTypes.func,
  };

  render() {
    const { onMenuClick, translate, resources } = this.props;
    return (
      <div>

        {
          // <DashboardMenuItem onClick={onMenuClick}/>

          // <Divider />
        }

        {resources.map(resource =>
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={translate(`resources.${resource.name}.name`, {
                smart_count: 2,
              })}
              leftIcon={<resource.icon />}
              onClick={onMenuClick}
            />)
        }

        <Divider />

        <MenuItemLink
          to={`/statistic`}
          primaryText={translate(`features.statistics.name`, {
            smart_count: 2,
          })}
          leftIcon={<StatisticIcon />}
          onClick={onMenuClick}
        />

      </div>
    );
  }
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
  locale: state.i18n.locale,
  resources: getResources(state),
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    {}
  ),
  translate
);

export default enhance(AppMenu);
