import React from 'react';
import {
  Resource,
  Datagrid,
  TextField,
  List,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  translate,
  EditButton
} from 'react-admin';
import ResourceTitle from '../components/ResourceTitle';
import ResourcePagination from '../components/ResourcePagination';
import ResourceIcon from '@material-ui/icons/Place';
import ResourceDefaultActions from '../components/ResourceDefaultActions';

const ResourceList = (props) => (
  <List {...props} sort={{ field: 'name', order: 'ASC' }} actions={<ResourceDefaultActions />} perPage={75} pagination={<ResourcePagination />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

const ResourceEdit = translate((props) => (
  <Edit title={<ResourceTitle source="name" />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="latitude" />
      <NumberInput source="longitude" />
      <NumberInput
        source="arrival_count"
        defaultValue={1}
        helperText={props.translate("resources.halts.helper.arrival_count")}
      />
    </SimpleForm>
  </Edit>
));

const ResourceCreate = translate((props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="latitude" />
      <NumberInput source="longitude" />
      <NumberInput
        source="arrival_count"
        defaultValue={1}
        helperText={props.translate("resources.halts.helper.arrival_count")}
      />
    </SimpleForm>
  </Create>
));

export default <Resource
  name="halts"
  icon={ResourceIcon}
  list={ResourceList}
  edit={ResourceEdit}
  create={ResourceCreate}
/>
