import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  ReferenceInput,
  Resource,
  SimpleFormIterator,
  TextField,
  TextInput
} from 'react-admin';
import ResourceIcon from '@material-ui/icons/Map';
import ResourceTitle from '../components/ResourceTitle';
import ResourcePagination from '../components/ResourcePagination';
import ChipInput from '../components/RouteTimeChipInputField';
import ResourceDefaultActions from '../components/ResourceDefaultActions';
import {SimpleFormWithDataTransform} from "../components/SimpleFormWithTransform";

/*

const copyRoute = async () => {
  const routeWithKids = await firestore.doc('/uedem/routes/list/TARzxTYuGAgn3ONxfHdm').get();

  const newRouteWithoutKids = {
    ...routeWithKids.data(),
    name: 'Montag - Freitag ( ohne Schüler )'
  };

  firestore.doc('/uedem/routes/list/NRpL5ARKqhtryh9zAVb9').set(newRouteWithoutKids).then(() => console.log('saved'));
};

*/

const ResourceList = (props) => (
  <List {...props} sort={{field: 'name', order: 'ASC'}} actions={<ResourceDefaultActions/>} perPage={75}
        pagination={<ResourcePagination/>}>
    <Datagrid rowClick="edit">
      <TextField source="name"/>
      <EditButton/>
    </Datagrid>
  </List>
);

const RouteStopsInput = (
  <ArrayInput source="stops" style={{width: '100%'}}>
    <SimpleFormIterator>

      <ReferenceInput
        source="halt"
        reference="halts"
        label="resources.routes.fields.halt"
        margin="normal"
        perPage={10000}
        sort={{field: 'name', order: 'ASC'}}
      >

        <AutocompleteInput optionText="name" margin="normal"/>

      </ReferenceInput>

      <ChipInput
        source="times"
        type="time"
        label="resources.routes.fields.times"/>

    </SimpleFormIterator>
  </ArrayInput>
);

function transformStopsToTimedStops(data) {
  const {stops} = data;

  let timedStops = [];

  stops.forEach(stop => {
    stop.times.forEach(time => {
      timedStops.push({
        time: time,
        halt: stop.halt,
      });
    });
  });

  timedStops = timedStops.sort((a, b) =>
    (a.time > b.time)
      ? 1
      : (a.time < b.time) ? -1 : 0
  );

  return {...data, timedStops}
}

const ResourceEdit = props => (
  <Edit title={<ResourceTitle source="name"/>} {...props}>
    <SimpleFormWithDataTransform submitOnEnter={false} transform={transformStopsToTimedStops}>
      <TextInput source="name"/>
      {RouteStopsInput}
    </SimpleFormWithDataTransform>
  </Edit>
);

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleFormWithDataTransform submitOnEnter={false} transform={transformStopsToTimedStops}>
      <TextInput source="name"/>
      {RouteStopsInput}
    </SimpleFormWithDataTransform>
  </Create>
);

export default <Resource
  name="routes"
  icon={ResourceIcon}
  list={ResourceList}
  edit={ResourceEdit}
  create={ResourceCreate}
/>
