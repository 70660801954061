import moment from "moment";

export function isStatisticNotSelected(appState) {
  return Object.keys(appState.statistic.statistic).length === 0;
}

export function selectSelectedStatisticType(appState) {
  return appState.statistic.statistic.report;
}

export function selectTimeRange(appState, type) {
  return isStatisticNotSelected(appState)
    ? '' : moment(appState.statistic.statistic.timeRange[type]).format('MMMM YYYY');
}

export function isBaseDataAvailable(state) {
  const {loadedBaseData} = state.statistic;

  if (Object.keys(loadedBaseData).length === 0) {
    return false;
  }

  return Object.entries(loadedBaseData)
    .map(set => set[1])
    .reduce((acc, isAvailable) => acc && isAvailable, true);
}

