import React from 'react';
import { connect } from 'react-redux';
import { userLogin, translate } from 'react-admin';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Typography
} from '@material-ui/core';
import logo from '../images/buergerbus-uedem-logo.png';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  logo: {
    margin: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 4,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 2,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const SignInCard = ({ classes, onSubmit, translate }) => <React.Fragment>
  <main className={classes.main}>
    <Paper className={classes.paper}>

      <img className={classes.logo} src={logo} alt="Logo" />
      <Typography component="h1" variant="h5">
        {translate("layout.loginPage.welcome")}
      </Typography>
      <form className={classes.form} onSubmit={onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">{translate("layout.loginPage.email")}</InputLabel>
          <Input id="email" name="email" autoComplete="email" autoFocus />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">{translate("ra.auth.password")}</InputLabel>
          <Input name="password" type="password" id="password" autoComplete="current-password" />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {translate("ra.auth.sign_in")}
        </Button>
      </form>
    </Paper>
  </main>
</React.Fragment>;

const StyledSignInCard = withStyles(styles)(SignInCard);

class LoginPage extends React.Component {
  submit = (e) => {
    e.preventDefault();
    // gather your data/credentials here
    const credentials = {
      username: e.target[0].value,
      password: e.target[1].value,
    };

    // Dispatch the userLogin action (injected by connect)
    this.props.userLogin(credentials);
  };

  render() {
    return (
      <MuiThemeProvider theme={this.props.theme}>
        <StyledSignInCard onSubmit={this.submit} translate={this.props.translate} />
      </MuiThemeProvider>
    );
  }
};

export default connect(undefined, { userLogin })(translate(LoginPage));
